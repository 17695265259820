import React, {createContext, useState} from 'react';
import DeliveryPartnerService from "../services/DeliveryPartnerService";
import {Toast} from "../components/common/Toast";

export const DeliveryPartnerContext = createContext("DeliveryPartnerContext");

const DeliveryPartnerContextProvider = ({children}) => {

    const [deliveryPartnerList, setDeliveryPartnerList] = useState([]);
    const [loadingDeliveryPartnerList, setLoadingDeliveryPartnerList] = useState(false);
    const [deliveryPartnerListErrorMsg, setDeliveryPartnerListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getDeliveryPartnerList = async (params) => {

        try {
            setLoadingDeliveryPartnerList(true);
            setDeliveryPartnerListErrorMsg('');
            const response = await DeliveryPartnerService.getDeliveryPartnerList(params);
            setDeliveryPartnerList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingDeliveryPartnerList(false);
        } catch (error) {
            setLoadingDeliveryPartnerList(false);
            const message = error.response ? error.response.data.message : error.message;
            setDeliveryPartnerListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <DeliveryPartnerContext.Provider
            value={{
                getDeliveryPartnerList,
                deliveryPartnerList,
                totalElements,
                loadingDeliveryPartnerList,
                deliveryPartnerListErrorMsg
            }}
        >
            {children}
        </DeliveryPartnerContext.Provider>
    );
}

export default DeliveryPartnerContextProvider;