import React, {createContext, useState} from 'react';
import OrderService from "../services/OrderService";
import {Toast} from "../components/common/Toast";

export const OrderContext = createContext("OrderContext");


const OrderContextProvider = ({children}) => {

    const [orderList, setOrderList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingOrderList, setLoadingOrderList] = useState(false);
    const [orderListErrorMsg, setOrderListErrorMsg] = useState('');

    const getOrderList = async (params) => {

        try {
            setLoadingOrderList(true);
            setOrderListErrorMsg('');
            const response = await OrderService.getOrdersList(params);
            setOrderList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingOrderList(false);
        } catch (error) {
            setLoadingOrderList(false);
            const message = error.response ? error.response.data : error.message;
            setOrderListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <OrderContext.Provider
            value={{
                getOrderList,
                orderList,
                loadingOrderList,
                orderListErrorMsg,
                totalElements
            }}
        >
            {children}
        </OrderContext.Provider>
    );
}

export default OrderContextProvider;