import React, {createContext, useState} from 'react';
import AreaService from "../services/AreaService";
import {Toast} from "../components/common/Toast";

export const AreaContext = createContext("AreaContext");

const AreaContextProvider = ({children}) => {

    const [areaList, setAreaList] = useState([]);
    const [loadingAreaList, setLoadingAreaList] = useState(false);
    const [areaListErrorMsg, setAreaListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getAreaList = async (params) => {

        try {
            setLoadingAreaList(true);
            setAreaListErrorMsg('');
            const response = await AreaService.getAreaList(params);
            setAreaList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingAreaList(false);
        } catch (error) {
            setLoadingAreaList(false);
            const message = error.response ? error.response.data.message : error.message;
            setAreaListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <AreaContext.Provider
            value={{
                getAreaList,
                areaList,
                totalElements,
                loadingAreaList,
                areaListErrorMsg
            }}
        >
            {children}
        </AreaContext.Provider>
    );
}

export default AreaContextProvider;