import React, {createContext, useState} from 'react';
import DailyDealsProductService from "../services/DailyDealsProductService";
import {Toast} from "../components/common/Toast";

export const DailyDealsProductContext = createContext("DailyDealsProductContext");

const DailyDealsProductContextProvider = ({children}) => {

    const [dailyDealsProductList, setDailyDealsProductList] = useState([]);
    const [loadingDailyDealsProductList, setLoadingDailyDealsProductList] = useState(false);
    const [dailyDealsProductListErrorMsg, setDailyDealsProductListErrorMsg] = useState('');

    const getDailyDealsProductList = async (page = 0, size = 10) => {

        try {
            setLoadingDailyDealsProductList(true);
            setDailyDealsProductListErrorMsg('');
            const response = await DailyDealsProductService.getDailyDealsProductsList(page, size);
            setDailyDealsProductList(response.data);
            setLoadingDailyDealsProductList(false);
        } catch (error) {
            setLoadingDailyDealsProductList(false);
            const message = error.response ? error.response.data.message ? error.response.data.message : error.response.data : error.message;
            setDailyDealsProductListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <DailyDealsProductContext.Provider
            value={{
                getDailyDealsProductList,
                dailyDealsProductList,
                loadingDailyDealsProductList,
                dailyDealsProductListErrorMsg
            }}
        >
            {children}
        </DailyDealsProductContext.Provider>
    );
}

export default DailyDealsProductContextProvider;