import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_DAILY_DEALS_PRODUCT_URL,
    GET_DAILY_DEALS_PRODUCT_BY_ID,
    GET_DAILY_DEALS_PRODUCT_LIST,
    POST_DAILY_DEALS_PRODUCT_URL,
    UPDATE_DAILY_DEALS_PRODUCT_URL
} from "../helpers/Constant";

export default class DailyDealsProductService {

    static getDailyDealsProductsList(params) {
        return axios.get(GET_DAILY_DEALS_PRODUCT_LIST, {...DailyDealsProductService.getAuthHeader(), params});
    }

    static addDailyDealsProduct(data) {
        return axios.post(POST_DAILY_DEALS_PRODUCT_URL, data, DailyDealsProductService.getAuthHeader());
    }

    static getDailyDealsProductById(id) {
        return axios.get(`${GET_DAILY_DEALS_PRODUCT_BY_ID}/${id}`, DailyDealsProductService.getAuthHeader());
    }

    static editDailyDealsProduct(id, data) {
        return axios.put(`${UPDATE_DAILY_DEALS_PRODUCT_URL}/${id}`, data, DailyDealsProductService.getAuthHeader());
    }

    static deleteDailyDealsProduct(id) {
        return axios.delete(`${DELETE_DAILY_DEALS_PRODUCT_URL}/${id}`, DailyDealsProductService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}