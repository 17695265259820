import React, {createContext, useState} from 'react';
import HomePageProductService from "../services/HomePageProductService";
import {Toast} from "../components/common/Toast";

export const HomePageProductContext = createContext("HomePageProductContext");

const HomePageProductContextProvider = ({children}) => {

    const [homePageProductList, setHomePageProductList] = useState([]);
    const [loadingHomePageProductList, setLoadingHomePageProductList] = useState(false);
    const [homePageProductListErrorMsg, setHomePageProductListErrorMsg] = useState('');

    const getHomePageProductList = async (page = 0, size = 10) => {

        try {
            setLoadingHomePageProductList(true);
            setHomePageProductListErrorMsg('');
            const response = await HomePageProductService.getHomePageProductsList(page, size);
            setHomePageProductList(response.data.content);
            setLoadingHomePageProductList(false);
        } catch (error) {
            setLoadingHomePageProductList(false);
            const message = error.response ? error.response.data.message ? error.response.data.message : error.response.data : error.message;
            setHomePageProductListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <HomePageProductContext.Provider
            value={{
                getHomePageProductList,
                homePageProductList,
                loadingHomePageProductList,
                homePageProductListErrorMsg
            }}
        >
            {children}
        </HomePageProductContext.Provider>
    );
}

export default HomePageProductContextProvider;