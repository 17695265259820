import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_STOCK_URL,
    GET_STOCK_BY_ID,
    GET_STOCK_LIST,
    POST_STOCK_URL,
    UPDATE_STOCK_URL
} from "../helpers/Constant";

export default class StockService {

    static getStocksList(params) {
        return axios.get(GET_STOCK_LIST, {...StockService.getAuthHeader(), params});
    }

    static addStock(data) {
        return axios.post(POST_STOCK_URL, data, StockService.getAuthHeader());
    }

    static getStockById(id) {
        return axios.get(`${GET_STOCK_BY_ID}/${id}`, StockService.getAuthHeader());
    }

    static editStock(id, data) {
        return axios.put(`${UPDATE_STOCK_URL}/${id}`, data, StockService.getAuthHeader());
    }

    static deleteStock(id) {
        return axios.delete(`${DELETE_STOCK_URL}/${id}`, StockService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}