import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_FEATURED_PRODUCT_URL,
    GET_FEATURED_PRODUCT_BY_ID,
    GET_FEATURED_PRODUCT_LIST,
    POST_FEATURED_PRODUCT_URL,
    UPDATE_FEATURED_PRODUCT_URL
} from "../helpers/Constant";

export default class FeaturedProductService {

    static getFeaturedProductsList(params) {
        return axios.get(GET_FEATURED_PRODUCT_LIST, {...FeaturedProductService.getAuthHeader(), params});
    }

    static addFeaturedProduct(data) {
        return axios.post(POST_FEATURED_PRODUCT_URL, data, FeaturedProductService.getAuthHeader());
    }

    static getFeaturedProductById(id) {
        return axios.get(`${GET_FEATURED_PRODUCT_BY_ID}/${id}`, FeaturedProductService.getAuthHeader());
    }

    static editFeaturedProduct(id, data) {
        return axios.put(`${UPDATE_FEATURED_PRODUCT_URL}/${id}`, data, FeaturedProductService.getAuthHeader());
    }

    static deleteFeaturedProduct(id) {
        return axios.delete(`${DELETE_FEATURED_PRODUCT_URL}/${id}`, FeaturedProductService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}