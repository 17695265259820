import axios from "axios";
import {
    ACCESS_TOKEN, GET_SELLER_LIST, 
    UPDATE_SELLER_PROFILE_URL, 
    VERIFY_SELLER_URL,
    CHANGE_SUBSCRIPTION_PLAN,
    GET_SELLER_ACCOUNTS
} from "../helpers/Constant";

export default class SellerService {

    static getSellersList(params) {
        return axios.get(GET_SELLER_LIST, {...SellerService.getAuthHeader(), params});
    }

    static updateSellerProfile(data) {
        return axios.put(UPDATE_SELLER_PROFILE_URL, data, SellerService.getAuthHeader());
    }
    
    static verifySeller(id, body) {
        return axios.put(`${VERIFY_SELLER_URL}/${id}`, body, SellerService.getAuthHeader());
    }

    static getSellerAccount(id) {
        return axios.get(`${GET_SELLER_ACCOUNTS}/${id}`, SellerService.getAuthHeader());
    }

    static changeSubscriptionPlan(id, body) {
        return axios.put(`${CHANGE_SUBSCRIPTION_PLAN}/${id}`, body, SellerService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": `${accessToken}`}};
    }

}