import React, {createContext, useState} from 'react';
import CustomerGroupService from "../services/CustomerGroupService";
import {Toast} from "../components/common/Toast";

export const CustomerGroupContext = createContext("CustomerGroupContext");

const CustomerGroupContextProvider = ({children}) => {

    const [customerGroupList, setCustomerGroupList] = useState([]);
    const [loadingCustomerGroupList, setLoadingCustomerGroupList] = useState(false);
    const [customerGroupListErrorMsg, setCustomerGroupListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getCustomerGroupList = async (params) => {

        try {
            setLoadingCustomerGroupList(true);
            setCustomerGroupListErrorMsg('');
            const response = await CustomerGroupService.getCustomerGroupList(params);
            setCustomerGroupList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingCustomerGroupList(false);
        } catch (error) {
            setLoadingCustomerGroupList(false);
            const message = error.response ? error.response.data.message : error.message;
            setCustomerGroupListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <CustomerGroupContext.Provider
            value={{
                getCustomerGroupList,
                customerGroupList,
                totalElements,
                loadingCustomerGroupList,
                customerGroupListErrorMsg
            }}
        >
            {children}
        </CustomerGroupContext.Provider>
    );
}

export default CustomerGroupContextProvider;