import axios from "axios";
import {ACCESS_TOKEN, LOGIN_URL, REFRESH_TOKEN} from "../helpers/Constant";

export default class AuthService {

    static login(data) {
        return axios.post(LOGIN_URL, data);
    }

    static refreshToken() {
        return axios.get(REFRESH_TOKEN, AuthService.getAuthHeader() );
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}