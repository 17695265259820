import React, {createContext, useState} from 'react';
import SubscriptionPlanService from "../services/SubscriptionPlanService";
import {Toast} from "../components/common/Toast";

export const SubscriptionPlanContext = createContext("SubscriptionPlanContext");

const SubscriptionPlanContextProvider = ({children}) => {

    const [subscriptionPlanList, setSubscriptionPlanList] = useState([]);
    const [loadingSubscriptionPlanList, setLoadingSubscriptionPlanList] = useState(false);
    const [subscriptionPlanListErrorMsg, setSubscriptionPlanListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getSubscriptionPlanList = async (params) => {

        try {
            setLoadingSubscriptionPlanList(true);
            setSubscriptionPlanListErrorMsg('');
            const response = await SubscriptionPlanService.getSubscriptionPlanList(params);
            setSubscriptionPlanList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingSubscriptionPlanList(false);
        } catch (error) {
            setLoadingSubscriptionPlanList(false);
            const message = error.response ? error.response.data.message : error.message;
            setSubscriptionPlanListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <SubscriptionPlanContext.Provider
            value={{
                getSubscriptionPlanList,
                subscriptionPlanList,
                totalElements,
                loadingSubscriptionPlanList,
                subscriptionPlanListErrorMsg
            }}
        >
            {children}
        </SubscriptionPlanContext.Provider>
    );
}

export default SubscriptionPlanContextProvider;