import axios from "axios";
import {
    ACCESS_TOKEN,
    ALL_PERMISSION_URL,
    GET_ROLE_BY_ID,
    GET_ROLE_LIST,
    POST_ROLE_URL,
    UPDATE_ROLE_URL
} from "../helpers/Constant";

export default class RoleService {

    static getRolesList(params) {
        return axios.get(GET_ROLE_LIST, {...RoleService.getAuthHeader(), params});
    }

    static addRole(data) {
        return axios.post(POST_ROLE_URL, data, RoleService.getAuthHeader());
    }

    static getRoleById(id) {
        return axios.get(`${GET_ROLE_BY_ID}/${id}`, RoleService.getAuthHeader());
    }

    static editRole(id, data) {
        return axios.put(`${UPDATE_ROLE_URL}/${id}`, data, RoleService.getAuthHeader());
    }

    static getAllPermission() {
        return axios.get(ALL_PERMISSION_URL, RoleService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}