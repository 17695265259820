import React, {createContext, useState} from 'react';
import CompanyService from "../services/CompanyService";
import {Toast} from "../components/common/Toast";

export const CompanyContext = createContext("CompanyContext");

const CompanyContextProvider = ({children}) => {

    const [companyList, setCompanyList] = useState([]);
    const [loadingCompanyList, setLoadingCompanyList] = useState(false);
    const [companyListErrorMsg, setCompanyListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getCompanyList = async (params) => {

        try {
            setLoadingCompanyList(true);
            setCompanyListErrorMsg('');
            const response = await CompanyService.getCompanyList(params);
            setCompanyList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingCompanyList(false);
        } catch (error) {
            setLoadingCompanyList(false);
            const message = error.response ? error.response.data.message : error.message;
            setCompanyListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <CompanyContext.Provider
            value={{
                getCompanyList,
                companyList,
                totalElements,
                loadingCompanyList,
                companyListErrorMsg
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
}

export default CompanyContextProvider;