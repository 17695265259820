import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_SELLER_LEDGER_URL,
    GET_SELLER_LEDGER_BY_ID,
    GET_SELLER_LEDGER_LIST,
    POST_SELLER_LEDGER_URL,
    UPDATE_SELLER_LEDGER_URL,
    CANCEL_SELLER_LEDGER_URL
} from "../helpers/Constant";

export default class SellerLedgerService {

    static getSellerLedgerList(id,params) {
        return axios.get(`${GET_SELLER_LEDGER_LIST}/${id}`, {...SellerLedgerService.getAuthHeader(), params});
    }

    static addSellerLedger(id,data) {
        return axios.post(`${POST_SELLER_LEDGER_URL}/${id}`, data, SellerLedgerService.getAuthHeader());
    }

    // static getSellerLedgerById(id) {
    //     return axios.get(`${GET_SELLER_LEDGER_BY_ID}/${id}`, SellerLedgerService.getAuthHeader());
    // }

    // static editSellerLedger(id, data) {
    //     return axios.put(`${UPDATE_SELLER_LEDGER_URL}/${id}`, data, SellerLedgerService.getAuthHeader());
    // }

    static cancelSellerLedger(id) {
        return axios.put(`${CANCEL_SELLER_LEDGER_URL}/${id}`,null, SellerLedgerService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}