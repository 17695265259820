import React, {createContext, useState} from 'react';
import BestSellerProductService from "../services/BestSellerProductService";
import {Toast} from "../components/common/Toast";

export const BestSellerProductContext = createContext("BestSellerProductContext");

const BestSellerProductContextProvider = ({children}) => {

    const [bestSellerProductList, setBestSellerProductList] = useState([]);
    const [loadingBestSellerProductList, setLoadingBestSellerProductList] = useState(false);
    const [bestSellerProductListErrorMsg, setBestSellerProductListErrorMsg] = useState('');

    const getBestSellerProductList = async (page = 0, size = 10) => {

        try {
            setLoadingBestSellerProductList(true);
            setBestSellerProductListErrorMsg('');
            const response = await BestSellerProductService.getBestSellerProductsList(page, size);
            setBestSellerProductList(response.data);
            setLoadingBestSellerProductList(false);
        } catch (error) {
            setLoadingBestSellerProductList(false);
            const message = error.response ? error.response.data.message ? error.response.data.message : error.response.data : error.message;
            setBestSellerProductListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <BestSellerProductContext.Provider
            value={{
                getBestSellerProductList,
                bestSellerProductList,
                loadingBestSellerProductList,
                bestSellerProductListErrorMsg
            }}
        >
            {children}
        </BestSellerProductContext.Provider>
    );
}

export default BestSellerProductContextProvider;