import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_BANNER_URL,
    GET_BANNER_BY_ID,
    GET_BANNER_LIST,
    POST_BANNER_URL,
    UPDATE_BANNER_URL
} from "../helpers/Constant";

export default class BannerService {

    static getBannerList(params) {
        return axios.get(GET_BANNER_LIST, {...BannerService.getAuthHeader(), params});
    }

    static addBanner(data) {
        return axios.post(POST_BANNER_URL, data, BannerService.getAuthHeader());
    }

    static getBannerById(id) {
        return axios.get(`${GET_BANNER_BY_ID}/${id}`, BannerService.getAuthHeader());
    }

    static deleteBanner(id) {
        return axios.delete(`${DELETE_BANNER_URL}/${id}`, BannerService.getAuthHeader());
    }

    static editBanner(id, data) {
        return axios.put(`${UPDATE_BANNER_URL}/${id}`, data, BannerService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}