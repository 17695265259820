import React, {createContext, useState} from 'react';
import SellerService from "../services/SellerService";
import {Toast} from "../components/common/Toast";

export const SellerContext = createContext("SellerContext");


const SellerContextProvider = ({children}) => {

    const [sellerList, setSellerList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingSellerList, setLoadingSellerList] = useState(false);
    const [sellerListErrorMsg, setSellerListErrorMsg] = useState('');

    const getSellerList = async (params) => {

        try {
            setLoadingSellerList(true);
            setSellerListErrorMsg('');
            const response = await SellerService.getSellersList(params);
            setSellerList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingSellerList(false);
        } catch (error) {
            setLoadingSellerList(false);
            const message = error.response ? error.response.data.message ? error.response.data.message : error.response.data : error.message;
            setSellerListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <SellerContext.Provider
            value={{
                getSellerList,
                sellerList,
                loadingSellerList,
                sellerListErrorMsg,
                totalElements
            }}
        >
            {children}
        </SellerContext.Provider>
    );
}

export default SellerContextProvider;