import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_CAMPAIGN_URL,
    GET_CAMPAIGN_BY_ID,
    GET_CAMPAIGN_LIST,
    POST_CAMPAIGN_URL,
    UPDATE_CAMPAIGN_URL
} from "../helpers/Constant";

export default class CampaignService {

    static getCampaignList(params) {
        return axios.get(GET_CAMPAIGN_LIST, {...CampaignService.getAuthHeader(), params});
    }

    static addCampaign(data) {
        return axios.post(POST_CAMPAIGN_URL, data, CampaignService.getAuthHeader());
    }

    static getCampaignById(id) {
        return axios.get(`${GET_CAMPAIGN_BY_ID}/${id}`, CampaignService.getAuthHeader());
    }

    static editCampaign(id, data) {
        return axios.put(`${UPDATE_CAMPAIGN_URL}/${id}`, data, CampaignService.getAuthHeader());
    }

    static deleteCampaign(id) {
        return axios.delete(`${DELETE_CAMPAIGN_URL}/${id}`, CampaignService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}