import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_ORDER_URL,
    GET_ORDER_BY_ID_URL,
    GET_ORDER_LIST_URL,
    PROCESS_ORDER_URL,
    UPDATE_CART_URL,
    UPDATE_ORDER_URL
} from "../helpers/Constant";

export default class OrderService {

    static getOrdersList(params) {
        return axios.get(GET_ORDER_LIST_URL, {...OrderService.getAuthHeader(), params});
    }

    static processOrder(data, id) {
        return axios.put(`${PROCESS_ORDER_URL}/${id}`, data, OrderService.getAuthHeader());
    }

    static getOrderById(id) {
        return axios.get(`${GET_ORDER_BY_ID_URL}/${id}`, OrderService.getAuthHeader());
    }

    static updateCart(id, data) {
        return axios.put(`${UPDATE_CART_URL}/${id}`, data, OrderService.getAuthHeader());
    }

    static updateOrder(id, data) {
        return axios.put(`${UPDATE_ORDER_URL}/${id}`, data, OrderService.getAuthHeader());
    }

    static deleteOrder(id) {
        return axios.delete(`${DELETE_ORDER_URL}/${id}`, OrderService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": `${accessToken}`}};
    }

}