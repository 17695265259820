import React, {createContext, useState} from 'react';
import CategoryService from "../services/CategoryService";
import {Toast} from "../components/common/Toast";

export const CategoryContext = createContext("CategoryContext");

const CategoryContextProvider = ({children}) => {

    const [categoryList, setCategoryList] = useState([]);
    const [parentCategoryList, setParentCategoryList] = useState([]);
    const [loadingCategoryList, setLoadingCategoryList] = useState(false);
    const [loadingParentCategoryList, setLoadingParentCategoryList] = useState(false);
    const [categoryListErrorMsg, setCategoryListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getCategoryList = async (params) => {
        try {
            setLoadingCategoryList(true);
            setCategoryListErrorMsg('');
            const response = await CategoryService.getCategoryList(params);
            setTotalElements(response.data.totalElements)
            setCategoryList(response.data.content);
            setLoadingCategoryList(false);
        } catch (error) {
            setLoadingCategoryList(false);
            const message = error.response ? error.response.data.message : error.message;
            setCategoryListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    const getParentCategoryList = async (params) => {

        try {
            setLoadingParentCategoryList(true);
            setCategoryListErrorMsg('');
            const response = await CategoryService.getParentCategoryList(params);
            setParentCategoryList(response.data);
            setLoadingParentCategoryList(false);
        } catch (error) {
            setLoadingParentCategoryList(false);
            const message = error.response ? error.response.data : error.message;
            Toast("error", "Error", message);
        }

    }

    return (
        <CategoryContext.Provider
            value={{
                getCategoryList,
                getParentCategoryList,
                categoryList,
                loadingCategoryList,
                categoryListErrorMsg,
                parentCategoryList,
                loadingParentCategoryList,
                totalElements
            }}
        >
            {children}
        </CategoryContext.Provider>
    );
}

export default CategoryContextProvider;