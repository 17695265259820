import React, {createContext, useState} from 'react';
import BannerService from "../services/BannerService";
import {Toast} from "../components/common/Toast";

export const BannerContext = createContext("BannerContext");

const BannerContextProvider = ({children}) => {

    const [bannerList, setBannerList] = useState([]);
    const [loadingBannerList, setLoadingBannerList] = useState(false);
    const [bannerListErrorMsg, setBannerListErrorMsg] = useState('');

    const getBannerList = async (params) => {

        try {
            setLoadingBannerList(true);
            setBannerListErrorMsg('');
            const response = await BannerService.getBannerList(params);
            setBannerList(response.data);
            setLoadingBannerList(false);
        } catch (error) {
            setLoadingBannerList(false);
            const message = error.response ? error.response.data.message : error.message;
            setBannerListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <BannerContext.Provider
            value={{
                getBannerList,
                bannerList,
                loadingBannerList,
                bannerListErrorMsg
            }}
        >
            {children}
        </BannerContext.Provider>
    );
}

export default BannerContextProvider;