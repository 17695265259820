import React, {createContext, useState} from 'react';
import FeaturedProductService from "../services/FeaturedProductService";
import {Toast} from "../components/common/Toast";

export const FeaturedProductContext = createContext("FeaturedProductContext");

const FeaturedProductContextProvider = ({children}) => {

    const [featuredProductList, setFeaturedProductList] = useState([]);
    const [loadingFeaturedProductList, setLoadingFeaturedProductList] = useState(false);
    const [featuredProductListErrorMsg, setFeaturedProductListErrorMsg] = useState('');

    const getFeaturedProductList = async (page = 0, size = 10) => {

        try {
            setLoadingFeaturedProductList(true);
            setFeaturedProductListErrorMsg('');
            const response = await FeaturedProductService.getFeaturedProductsList(page, size);
            setFeaturedProductList(response.data);
            setLoadingFeaturedProductList(false);
        } catch (error) {
            setLoadingFeaturedProductList(false);
            const message = error.response ? error.response.data.message ? error.response.data.message : error.response.data : error.message;
            setFeaturedProductListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <FeaturedProductContext.Provider
            value={{
                getFeaturedProductList,
                featuredProductList,
                loadingFeaturedProductList,
                featuredProductListErrorMsg
            }}
        >
            {children}
        </FeaturedProductContext.Provider>
    );
}

export default FeaturedProductContextProvider;