import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_HOME_PAGE_PRODUCT_URL,
    GET_HOME_PAGE_PRODUCT_BY_ID,
    GET_HOME_PAGE_PRODUCT_LIST,
    POST_HOME_PAGE_PRODUCT_URL,
    UPDATE_HOME_PAGE_PRODUCT_URL
} from "../helpers/Constant";

export default class HomePageProductService {

    static getHomePageProductsList(params) {
        return axios.get(GET_HOME_PAGE_PRODUCT_LIST, {...HomePageProductService.getAuthHeader(), params});
    }

    static addHomePageProduct(data) {
        return axios.post(POST_HOME_PAGE_PRODUCT_URL, data, HomePageProductService.getAuthHeader());
    }

    static getHomePageProductById(id) {
        return axios.get(`${GET_HOME_PAGE_PRODUCT_BY_ID}/${id}`, HomePageProductService.getAuthHeader());
    }

    static editHomePageProduct(id, data) {
        return axios.put(`${UPDATE_HOME_PAGE_PRODUCT_URL}/${id}`, data, HomePageProductService.getAuthHeader());
    }

    static deleteHomepageProduct(id) {
        return axios.delete(`${DELETE_HOME_PAGE_PRODUCT_URL}/${id}`, HomePageProductService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}