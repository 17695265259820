import React, {createContext, useState} from 'react';
import GlobalConfigService from "../services/GlobalConfigService";
import {Toast} from "../components/common/Toast";

export const GlobalConfigContext = createContext("GlobalConfigContext");

const GlobalConfigContextProvider = ({children}) => {

    const [globalConfigList, setGlobalConfigList] = useState([]);
    const [loadingGlobalConfigList, setLoadingGlobalConfigList] = useState(false);
    const [globalConfigListErrorMsg, setGlobalConfigListErrorMsg] = useState('');

    const getGlobalConfigList = async (params) => {

        try {
            setLoadingGlobalConfigList(true);
            setGlobalConfigListErrorMsg('');
            const response = await GlobalConfigService.getGlobalConfigList(params);
            setGlobalConfigList(response.data);
            setLoadingGlobalConfigList(false);
        } catch (error) {
            setLoadingGlobalConfigList(false);
            const message = error.response ? error.response.data.message : error.message;
            setGlobalConfigListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <GlobalConfigContext.Provider
            value={{
                getGlobalConfigList,
                globalConfigList,
                loadingGlobalConfigList,
                globalConfigListErrorMsg
            }}
        >
            {children}
        </GlobalConfigContext.Provider>
    );
}

export default GlobalConfigContextProvider;