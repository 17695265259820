import React, {createContext, useState} from 'react';
import CampaignService from "../services/CampaignService";
import {Toast} from "../components/common/Toast";

export const CampaignContext = createContext("CampaignContext");

const CampaignContextProvider = ({children}) => {

    const [campaignList, setCampaignList] = useState([]);
    const [loadingCampaignList, setLoadingCampaignList] = useState(false);
    const [campaignListErrorMsg, setCampaignListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getCampaignList = async (params) => {

        try {
            setLoadingCampaignList(true);
            setCampaignListErrorMsg('');
            const response = await CampaignService.getCampaignList(params);
            setCampaignList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingCampaignList(false);
        } catch (error) {
            setLoadingCampaignList(false);
            const message = error.response ? error.response.data.message : error.message;
            setCampaignListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <CampaignContext.Provider
            value={{
                getCampaignList,
                campaignList,
                totalElements,
                loadingCampaignList,
                campaignListErrorMsg
            }}
        >
            {children}
        </CampaignContext.Provider>
    );
}

export default CampaignContextProvider;