import React, {createContext, useState} from 'react';
import {ACCESS_TOKEN, PROFILE} from '../helpers/Constant';
import AuthService from "../services/AuthService";
import {Toast} from "../components/common/Toast";
import UserService from "../services/UserService";
import { useQuery } from '../helpers/Utils';

export const AuthContext = createContext("AuthContext");

const auth = localStorage.getItem(ACCESS_TOKEN);
const user = localStorage.getItem(PROFILE);

const AuthContextProvider = ({children}) => {

    const [isLogin, setIsLogin] = useState(!!auth);
    const [profile, setProfile] = useState(user ? JSON.parse(user) : null);
    const [role, setRole] = useState(user && JSON.parse(user).role ? JSON.parse(user).role.alias : null);
    const [loading, setLoading] = useState(false);
    const [profileLoading, setProfileLoading] = useState(false);

    const login = async (credential) => {

        try {
            setLoading(true);
            const response = await AuthService.login(credential);
            localStorage.setItem(ACCESS_TOKEN, response.data.token);
            localStorage.setItem(PROFILE, JSON.stringify(response.data.user));
            setProfile(response.data.user);
            setRole(response.data.user.role.alias);
            setIsLogin(true);
            setLoading(false);
        } catch (error) {
            const message = error.response ? error.response.data : error.message;
            Toast("error", "Error", message);
            setLoading(false);
            setIsLogin(false);
        }

    }

    const refreshToken = async () => {

        try {
            setLoading(true);
            const response = await AuthService.refreshToken();
            localStorage.setItem(ACCESS_TOKEN, response.data.token);
            localStorage.setItem(PROFILE, JSON.stringify(response.data.user));
            setProfile(response.data.user);
            setRole(response.data.user.role.alias);
            setIsLogin(true);
            setLoading(false);
        } catch (error) {
            const message = error.response ? error.response.data : error.message;
            Toast("error", "Error", message);
            setLoading(false);
            setIsLogin(false);
        }

    }

    const getUserProfile = async () => {

        try {
            setProfileLoading(true);
            const response = await UserService.getUserProfile();
            if(response.data.needToRefreshToken){
                await refreshToken();
                return;
            }
            localStorage.setItem(PROFILE, JSON.stringify(response.data));
            setProfile(response.data);
            setIsLogin(true);
            setRole(response.data.role.alias);
            setProfileLoading(false);
        } catch (error) {
            const message = error.response ? error.response.data : error.message;
            Toast("error", "Error", message);
            setProfileLoading(false);
        }

    }

    const logout = () => {
        setIsLogin(false);
        localStorage.clear();
    }

    return (
        <AuthContext.Provider
            value={{
                isLogin,
                profile,
                loading,
                profileLoading,
                role,
                permissions: profile ? profile.permissions : [],
                login,
                logout,
                getUserProfile
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;