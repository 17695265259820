import React from 'react';
import ReactDOM from 'react-dom';

/* SCSS */
import 'antd/dist/antd.css';
import './assets/scss/style.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthContextProvider from './contexts/AuthContextProvider';
import ProductContextProvider from './contexts/ProductContextProvider';
import UserContextProvider from './contexts/UserContextProvider';
import BannerContextProvider from './contexts/BannerContextProvider';
import CategoryContextProvider from './contexts/CategoryContextProvider';
import CompanyContextProvider from './contexts/CompanyContextProvider';
import OrderContextProvider from './contexts/OrderContextProvider';
import RoleContextProvider from './contexts/RoleContextProvider';
import HomePageProductContextProvider from "./contexts/HomePageProductContextProvider";
import StaticPageContextProvider from './contexts/StaticPageContextProvider';
import FeaturedProductContextProvider from './contexts/FeaturedProductContextProvider';
import DailyDealsProductContextProvider from './contexts/DailyDealsProductContextProvider';
import BestSellerProductContextProvider from './contexts/BestSellerProductContextProvider';
import WarehouseContextProvider from './contexts/WarehouseContextProvider';
import GlobalConfigContextProvider from './contexts/GlobalConfigContextProvider';
import StockContextProvider from "./contexts/StockContextProvider";
import PromoCodeContextProvider from './contexts/PromoCodeContextProvider';
import StockOutContextProvider from "./contexts/StockOutContextProvider";
import SellerContextProvider from "./contexts/SellerContextProvider";
import ChattingContextProvider from './contexts/ChattingContextProvider';
import AreaContextProvider from './contexts/AreaContextProvider';
import { useQuery } from './helpers/Utils';
import { ACCESS_TOKEN } from './helpers/Constant';
import SubscriptionPlanContextProvider from './contexts/SubscriptionPlanContextProvider';
import SellerLedgerContextProvider from './contexts/SellerLedgerContextProvider';
import ProductBundleOfferContextProvider from './contexts/ProductBundleOfferContextProvider';
import DeliveryPartnerContextProvider from './contexts/DeliveryPartnerContextProvider';
import DeliveryPartnerLedgerContextProvider from './contexts/DeliveryPartnerLedgerContextProvider';
import CustomerGroupContextProvider from './contexts/CustomerGroupContextProvider.js';
import CampaignContextProvider from './contexts/CampaignContextProvider';

const contextProviders = [
    AuthContextProvider,
    ProductContextProvider,
    UserContextProvider,
    BannerContextProvider,
    CategoryContextProvider,
    CompanyContextProvider,
    OrderContextProvider,
    RoleContextProvider,
    HomePageProductContextProvider,
    FeaturedProductContextProvider,
    DailyDealsProductContextProvider,
    BestSellerProductContextProvider,
    WarehouseContextProvider,
    StaticPageContextProvider,
    GlobalConfigContextProvider,
    StockContextProvider,
    PromoCodeContextProvider,
    StockOutContextProvider,
    SellerContextProvider,
    ChattingContextProvider,
    AreaContextProvider,
    SubscriptionPlanContextProvider,
    SellerLedgerContextProvider,
    ProductBundleOfferContextProvider,
    DeliveryPartnerContextProvider,
    DeliveryPartnerLedgerContextProvider,
    CustomerGroupContextProvider,
    CampaignContextProvider

]

const getAppWithContextProviders = () => {

    let result = <App/>
    contextProviders.forEach(Provider => result = <Provider>{result}</Provider>);

    return result;
}

ReactDOM.render(
    // <React.StrictMode>
    getAppWithContextProviders(),
    // </React.StrictMode>
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//KiE4fkJ08O7tL25Qer
