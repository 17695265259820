import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_STOCK_OUT_URL,
    GET_STOCK_OUT_BY_ID,
    GET_STOCK_OUT_LIST,
    POST_STOCK_OUT_URL,
    UPDATE_STOCK_OUT_URL,
    VERIFY_STOCK_URL
} from "../helpers/Constant";

export default class StockOutService {

    static getStockOutsList(params) {
        return axios.get(GET_STOCK_OUT_LIST, {...StockOutService.getAuthHeader(), params});
    }

    static addStockOut(data) {
        return axios.post(POST_STOCK_OUT_URL, data, StockOutService.getAuthHeader());
    }

    static verifyStock(id,data) {
        return axios.put(`${VERIFY_STOCK_URL}/${id}`, data, StockOutService.getAuthHeader());
    }

    static getStockOutById(id) {
        return axios.get(`${GET_STOCK_OUT_BY_ID}/${id}`, StockOutService.getAuthHeader());
    }

    static editStockOut(id, data) {
        return axios.put(`${UPDATE_STOCK_OUT_URL}/${id}`, data, StockOutService.getAuthHeader());
    }

    static deleteStockOut(id) {
        return axios.delete(`${DELETE_STOCK_OUT_URL}/${id}`, StockOutService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}