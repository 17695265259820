import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_WAREHOUSE_URL,
    GET_WAREHOUSE_BY_ID,
    GET_WAREHOUSE_LIST,
    POST_WAREHOUSE_URL,
    UPDATE_WAREHOUSE_URL,
    GET_WAREHOUSES_TO_SEND_STOCK
} from "../helpers/Constant";

export default class WarehouseService {

    static getWarehouseList(params) {
        return axios.get(GET_WAREHOUSE_LIST, {...WarehouseService.getAuthHeader(), params});
    }

    static addWarehouse(data) {
        return axios.post(POST_WAREHOUSE_URL, data, WarehouseService.getAuthHeader());
    }

    static getWarehouseListToSendStock () {
        return axios.get(GET_WAREHOUSES_TO_SEND_STOCK, WarehouseService.getAuthHeader());
    }

    static getWarehouseById(id) {
        return axios.get(`${GET_WAREHOUSE_BY_ID}/${id}`, WarehouseService.getAuthHeader());
    }

    static editWarehouse(id, data) {
        return axios.put(`${UPDATE_WAREHOUSE_URL}/${id}`, data, WarehouseService.getAuthHeader());
    }

    static deleteWarehouse(id) {
        return axios.delete(`${DELETE_WAREHOUSE_URL}/${id}`, WarehouseService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}