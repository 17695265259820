import React, {createContext, useState} from 'react';
import WarehouseService from "../services/WarehouseService";
import {Toast} from "../components/common/Toast";

export const WarehouseContext = createContext("WarehouseContext");

const WarehouseContextProvider = ({children}) => {

    const [warehouseList, setWarehouseList] = useState([]);
    const [loadingWarehouseList, setLoadingWarehouseList] = useState(false);
    const [warehouseListErrorMsg, setWarehouseListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getWarehouseList = async (params) => {

        try {
            setLoadingWarehouseList(true);
            setWarehouseListErrorMsg('');
            const response = await WarehouseService.getWarehouseList(params);
            setWarehouseList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingWarehouseList(false);
        } catch (error) {
            setLoadingWarehouseList(false);
            const message = error.response ? error.response.data : error.message;
            setWarehouseListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    const getWarehouseListToSendStock = async () => {

        try {
            setLoadingWarehouseList(true);
            setWarehouseListErrorMsg('');
            const response = await WarehouseService.getWarehouseListToSendStock();
            setWarehouseList(response.data);
            setLoadingWarehouseList(false);
        } catch (error) {
            setLoadingWarehouseList(false);
            const message = error.response ? error.response.data : error.message;
            setWarehouseListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <WarehouseContext.Provider
            value={{
                getWarehouseList,
                getWarehouseListToSendStock,
                warehouseList,
                totalElements,
                loadingWarehouseList,
                warehouseListErrorMsg
            }}
        >
            {children}
        </WarehouseContext.Provider>
    );
}

export default WarehouseContextProvider;