import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_PROMO_CODE_URL,
    GET_PROMO_CODE_BY_ID,
    GET_PROMO_CODE_LIST,
    POST_PROMO_CODE_URL,
    UPDATE_PROMO_CODE_URL
} from "../helpers/Constant";

export default class PromoCodeService {

    static getPromoCodeList(params) {
        return axios.get(GET_PROMO_CODE_LIST, {...PromoCodeService.getAuthHeader(), params});
    }

    static addPromoCode(data) {
        return axios.post(POST_PROMO_CODE_URL, data, PromoCodeService.getAuthHeader());
    }

    static getPromoCodeById(id) {
        return axios.get(`${GET_PROMO_CODE_BY_ID}/${id}`, PromoCodeService.getAuthHeader());
    }

    static editPromoCode(id, data) {
        return axios.put(`${UPDATE_PROMO_CODE_URL}/${id}`, data, PromoCodeService.getAuthHeader());
    }

    static deletePromoCode(id) {
        return axios.delete(`${DELETE_PROMO_CODE_URL}/${id}`, PromoCodeService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}