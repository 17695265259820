import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_DELIVERY_PARTNER_URL,
    GET_DELIVERY_PARTNER_BY_ID,
    GET_DELIVERY_PARTNER_LIST,
    POST_DELIVERY_PARTNER_URL,
    UPDATE_DELIVERY_PARTNER_URL,
    GET_DELIVERY_PARTNER_ACCOUNTS
} from "../helpers/Constant";

export default class DeliveryPartnerService {

    static getDeliveryPartnerList(params) {
        return axios.get(GET_DELIVERY_PARTNER_LIST, {...DeliveryPartnerService.getAuthHeader(), params});
    }

    static addDeliveryPartner(data) {
        return axios.post(POST_DELIVERY_PARTNER_URL, data, DeliveryPartnerService.getAuthHeader());
    }

    static getDeliveryPartnerById(id) {
        return axios.get(`${GET_DELIVERY_PARTNER_BY_ID}/${id}`, DeliveryPartnerService.getAuthHeader());
    }

    static editDeliveryPartner(id, data) {
        return axios.put(`${UPDATE_DELIVERY_PARTNER_URL}/${id}`, data, DeliveryPartnerService.getAuthHeader());
    }

    static deleteDeliveryPartner(id) {
        return axios.delete(`${DELETE_DELIVERY_PARTNER_URL}/${id}`, DeliveryPartnerService.getAuthHeader());
    }

    static getDeliveryPartnerAccount(id) {
        return axios.get(`${GET_DELIVERY_PARTNER_ACCOUNTS}/${id}`, DeliveryPartnerService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}