import React, {createContext, useState} from 'react';
import StaticPageService from "../services/StaticPageService";
import {Toast} from "../components/common/Toast";

export const StaticPageContext = createContext("StaticPageContext");

const StaticPageContextProvider = ({children}) => {

    const [staticPageList, setStaticPageList] = useState([]);
    const [loadingStaticPageList, setLoadingStaticPageList] = useState(false);
    const [staticPageListErrorMsg, setStaticPageListErrorMsg] = useState('');

    const getStaticPageList = async (params) => {

        try {
            setLoadingStaticPageList(true);
            setStaticPageListErrorMsg('');
            const response = await StaticPageService.getStaticPagesList(params);
            setStaticPageList(response.data);
            setLoadingStaticPageList(false);
        } catch (error) {
            setLoadingStaticPageList(false);
            const message = error.response ? error.response.data.message : error.message;
            setStaticPageListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <StaticPageContext.Provider
            value={{
                getStaticPageList,
                staticPageList,
                loadingStaticPageList,
                staticPageListErrorMsg
            }}
        >
            {children}
        </StaticPageContext.Provider>
    );
}

export default StaticPageContextProvider;