import React, {createContext, useState} from 'react';
import DeliveryPartnerLedgerService from "../services/DeliveryPartnerLedgerService";
import {Toast} from "../components/common/Toast";

export const DeliveryPartnerLedgerContext = createContext("DeliveryPartnerLedgerContext");

const DeliveryPartnerLedgerContextProvider = ({children}) => {

    const [deliveryPartnerLedgerList, setDeliveryPartnerLedgerList] = useState([]);
    const [loadingDeliveryPartnerLedgerList, setLoadingDeliveryPartnerLedgerList] = useState(false);
    const [deliveryPartnerLedgerListErrorMsg, setDeliveryPartnerLedgerListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getDeliveryPartnerLedgerList = async (id,params) => {

        try {
            setLoadingDeliveryPartnerLedgerList(true);
            setDeliveryPartnerLedgerListErrorMsg('');
            const response = await DeliveryPartnerLedgerService.getDeliveryPartnerLedgerList(id, params);
            setDeliveryPartnerLedgerList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingDeliveryPartnerLedgerList(false);
        } catch (error) {
            setLoadingDeliveryPartnerLedgerList(false);
            const message = error.response ? error.response.data.message : error.message;
            setDeliveryPartnerLedgerListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <DeliveryPartnerLedgerContext.Provider
            value={{
                getDeliveryPartnerLedgerList,
                deliveryPartnerLedgerList,
                totalElements,
                loadingDeliveryPartnerLedgerList,
                deliveryPartnerLedgerListErrorMsg
            }}
        >
            {children}
        </DeliveryPartnerLedgerContext.Provider>
    );
}

export default DeliveryPartnerLedgerContextProvider;