import {useLocation} from 'react-router-dom';
import Permission from "./Permission";

export const getErrorMessage = (error) => {
    if (error.response) {
        return error.response.data;
    } else {
        return error.message;
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: query.get(key) || ""}
    }
    return data;
}

export const isValueExistInSearch = (data) => {

    for (const key of Object.keys(data)) {
        if (data[key]) {
            return ["1"];
        }
    }
    return ["0"];
}

export const getBase64Image = (data) => {
    const base64 = btoa(
        new Uint8Array(data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

    return "data:;base64," + base64;
}

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key]) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        if (index === 0) {
            result += `?${key}=${params[key]}`;
        } else {
            result += `&${key}=${params[key]}`;
        }

    });

    return result;
}

export const getFullPreviousRoute = (history) => {
    return `${history.location.pathname}${history.location.search}`;
}

export const resetState = (data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: ""}
    }
    return data;
}

export const getAllNonObjectValues = (data) => {

    let singleValue = {};

    for (const [key, value] of Object.entries(data)) {
        if (typeof value !== "object") {
            singleValue[key] = value;
        }
    }
    return singleValue;
}

export const getAllNestedObjectValues = (data) => {
    let singleValue = [];

    for (const [key, value] of Object.entries(data)) {
        if (value && typeof value === "object") {
            singleValue.push(value);
        }
    }
    return singleValue
}

export const booleanTagColor = (isTrue) => {
    return isTrue ? "blue" : "red";
}

export const getPercentageValue = (value, percent) => {
    return (value * percent) / 100;
}

export const getDiscountAmount = (e) => {
    if (e.product.discountType === "FLAT") {
        return e.product.discountAmount * e.quantity;
    } else {
        return getPercentageValue(e.unitPrice ?? e.product.salePrice, e.product.discountPercentage) * e.quantity;
    }
}

export const getProductDiscount = (product) => {
    if (product.discountType === "FLAT") {
        return product.discountAmount;
    } else {
        return getPercentageValue(product.salePrice, product.discountPercentage);
    }
}

export const getProductPrice = (product) => {
    return product.salePrice - getProductDiscount(product) + getPercentageValue(product.salePrice, product.vat);
}

export const hasPermission = (loggedInUserPermissions, permissions) => {

    if (!permissions) {
        return false;
    }

    if (permissions.includes(Permission.ALL)) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    for (const permission of permissions) {

        if (loggedInUserPermissions.includes(permission)) {
            return true;
        }

    }

    return false;
}