import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_BEST_SELLER_PRODUCT_URL,
    GET_BEST_SELLER_PRODUCT_BY_ID,
    GET_BEST_SELLER_PRODUCT_LIST,
    POST_BEST_SELLER_PRODUCT_URL,
    UPDATE_BEST_SELLER_PRODUCT_URL
} from "../helpers/Constant";

export default class BestSellerProductService {

    static getBestSellerProductsList(params) {
        return axios.get(GET_BEST_SELLER_PRODUCT_LIST, {...BestSellerProductService.getAuthHeader(), params});
    }

    static addBestSellerProduct(data) {
        return axios.post(POST_BEST_SELLER_PRODUCT_URL, data, BestSellerProductService.getAuthHeader());
    }

    static getBestSellerProductById(id) {
        return axios.get(`${GET_BEST_SELLER_PRODUCT_BY_ID}/${id}`, BestSellerProductService.getAuthHeader());
    }

    static editBestSellerProduct(id, data) {
        return axios.put(`${UPDATE_BEST_SELLER_PRODUCT_URL}/${id}`, data, BestSellerProductService.getAuthHeader());
    }

    static deleteBestSellerProduct(id) {
        return axios.delete(`${DELETE_BEST_SELLER_PRODUCT_URL}/${id}`, BestSellerProductService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}