import React, {createContext, useState} from 'react';
import ChattingService from "../services/ChattingService";
import {Toast} from "../components/common/Toast";

export const ChattingContext = createContext("ChattingContext");

const ChattingContextProvider = ({children}) => {

    const [chattingList, setChattingList] = useState([]);
    const [loadingChattingList, setLoadingChattingList] = useState(false);
    const [chattingListErrorMsg, setChattingListErrorMsg] = useState('');
    const [loadingMore, setLoadingMore] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const getChattingList = async () => {

        try {
            setLoadingChattingList(true);
            setChattingListErrorMsg('');
            const response = await ChattingService.getChattingList({page: 1, size:20});
            setChattingList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingChattingList(false);
        } catch (error) {
            setLoadingChattingList(false);
            const message = error.response ? error.response.data.message : error.message;
            setChattingListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    const getMoreChattingList = async () => {

        try {
            setLoadingMore(true);
            setChattingListErrorMsg('');
            const response = await ChattingService.getChattingList({page:currentPage + 1, size: 20});
            setCurrentPage(currentPage + 1);
            setTotalElements(response.data.totalElements);
            setChattingList([...chattingList, ...response.data.content]);
            setLoadingMore(false);
        } catch (error) {
            setLoadingMore(false);
            const message = error.response ? error.response.data.message : error.message;
            setChattingListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <ChattingContext.Provider
            value={{
                getChattingList,
                chattingList,
                loadingChattingList,
                chattingListErrorMsg,
                getMoreChattingList,
                loadingMore,
                totalElements
            }}
        >
            {children}
        </ChattingContext.Provider>
    );
}

export default ChattingContextProvider;