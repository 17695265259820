import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_CATEGORY_URL,
    GET_CATEGORY_BY_ID,
    GET_CATEGORY_LIST,
    GET_PARENT_CATEGORY_LIST,
    MAKE_HOT_CATEGORY_URL,
    POST_CATEGORY_URL,
    UPDATE_CATEGORY_URL,
    VERIFY_CATEGORY_URL
} from "../helpers/Constant";

export default class CategoryService {

    static getCategoryList(params) {
        return axios.get(GET_CATEGORY_LIST, {...CategoryService.getAuthHeader(), params});
    }

    static getParentCategoryList(params) {
        return axios.get(GET_PARENT_CATEGORY_LIST, {...CategoryService.getAuthHeader(), params});
    }

    static addCategory(data) {
        return axios.post(POST_CATEGORY_URL, data, CategoryService.getAuthHeader());
    }

    static editCategory(id, data) {
        return axios.put(`${UPDATE_CATEGORY_URL}/${id}`, data, CategoryService.getAuthHeader());
    }

    static deleteCategory(id) {
        return axios.delete(`${DELETE_CATEGORY_URL}/${id}`, CategoryService.getAuthHeader());
    }

    static verifyCategory(id) {
        return axios.put(`${VERIFY_CATEGORY_URL}/${id}`, null, {
            ...CategoryService.getAuthHeader(),
            params: {approved: true}
        });
    }

    static makeHotCategory(id, hot) {
        return axios.put(`${MAKE_HOT_CATEGORY_URL}/${id}`, null, {
            ...CategoryService.getAuthHeader(),
            params: {hot}
        });
    }

    static getCategoryById(id) {
        return axios.get(`${GET_CATEGORY_BY_ID}/${id}`, CategoryService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": `${accessToken}`}};
    }

}