import axios from "axios";
import {
    ACCESS_TOKEN,
    GET_ALL_CHATTING_LIST,
    GET_CHATTING_HISTORY_BY_USER_ID
} from "../helpers/Constant";

export default class ChattingService {

    static getChattingList(params) {
        return axios.get(GET_ALL_CHATTING_LIST, {...ChattingService.getAuthHeader(), params});
    }

    static getChattingHistoryByUser(id, params) {
        return axios.get(`${GET_CHATTING_HISTORY_BY_USER_ID}/${id}`, {...ChattingService.getAuthHeader(), params});
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": `${accessToken}`}};
    }

}