import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_AREA_URL,
    GET_AREA_BY_ID,
    GET_AREA_LIST,
    POST_AREA_URL,
    UPDATE_AREA_URL
} from "../helpers/Constant";

export default class AreaService {

    static getAreaList(params) {
        return axios.get(GET_AREA_LIST, {...AreaService.getAuthHeader(), params});
    }

    static addArea(data) {
        return axios.post(POST_AREA_URL, data, AreaService.getAuthHeader());
    }

    static getAreaById(id) {
        return axios.get(`${GET_AREA_BY_ID}/${id}`, AreaService.getAuthHeader());
    }

    static editArea(id, data) {
        return axios.put(`${UPDATE_AREA_URL}/${id}`, data, AreaService.getAuthHeader());
    }

    static deleteArea(id) {
        return axios.delete(`${DELETE_AREA_URL}/${id}`, AreaService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}