import React, {createContext, useState} from 'react';
import StockService from "../services/StockService";
import {Toast} from "../components/common/Toast";

export const StockContext = createContext("StockContext");

const StockContextProvider = ({children}) => {

    const [stockList, setStockList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingStockList, setLoadingStockList] = useState(false);
    const [stockListErrorMsg, setStockListErrorMsg] = useState('');

    const getStockList = async (page = 0, size = 10) => {

        try {
            setLoadingStockList(true);
            setStockListErrorMsg('');
            const response = await StockService.getStocksList(page, size);
            setStockList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingStockList(false);
        } catch (error) {
            setLoadingStockList(false);
            const message = error.response ? error.response.data.message ? error.response.data.message : error.response.data : error.message;
            setStockListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <StockContext.Provider
            value={{
                getStockList,
                stockList,
                totalElements,
                loadingStockList,
                stockListErrorMsg
            }}
        >
            {children}
        </StockContext.Provider>
    );
}

export default StockContextProvider;