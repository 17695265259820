import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_PRODUCT_BUNDLE_OFFER_URL,
    GET_PRODUCT_BUNDLE_OFFER_BY_ID,
    GET_PRODUCT_BUNDLE_OFFER_LIST,
    POST_PRODUCT_BUNDLE_OFFER_URL,
    UPDATE_PRODUCT_BUNDLE_OFFER_URL
} from "../helpers/Constant";

export default class ProductBundleOfferService {

    static getProductBundleOfferList(params) {
        return axios.get(GET_PRODUCT_BUNDLE_OFFER_LIST, {...ProductBundleOfferService.getAuthHeader(), params});
    }

    static addProductBundleOffer(data) {
        return axios.post(POST_PRODUCT_BUNDLE_OFFER_URL, data, ProductBundleOfferService.getAuthHeader());
    }

    static getProductBundleOfferById(id) {
        return axios.get(`${GET_PRODUCT_BUNDLE_OFFER_BY_ID}/${id}`, ProductBundleOfferService.getAuthHeader());
    }

    static editProductBundleOffer(id, data) {
        return axios.put(`${UPDATE_PRODUCT_BUNDLE_OFFER_URL}/${id}`, data, ProductBundleOfferService.getAuthHeader());
    }

    static deleteProductBundleOffer(id) {
        return axios.delete(`${DELETE_PRODUCT_BUNDLE_OFFER_URL}/${id}`, ProductBundleOfferService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}