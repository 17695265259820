import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_CUSTOMER_GROUP_URL,
    GET_CUSTOMER_GROUP_BY_ID,
    GET_CUSTOMER_GROUP_LIST,
    POST_CUSTOMER_GROUP_URL,
    UPDATE_CUSTOMER_GROUP_URL
} from "../helpers/Constant";

export default class CustomerGroupService {

    static getCustomerGroupList(params) {
        return axios.get(GET_CUSTOMER_GROUP_LIST, {...CustomerGroupService.getAuthHeader(), params});
    }

    static addCustomerGroup(data) {
        return axios.post(POST_CUSTOMER_GROUP_URL, data, CustomerGroupService.getAuthHeader());
    }

    static getCustomerGroupById(id) {
        return axios.get(`${GET_CUSTOMER_GROUP_BY_ID}/${id}`, CustomerGroupService.getAuthHeader());
    }

    static editCustomerGroup(id, data) {
        return axios.put(`${UPDATE_CUSTOMER_GROUP_URL}/${id}`, data, CustomerGroupService.getAuthHeader());
    }

    static deleteCustomerGroup(id) {
        return axios.delete(`${DELETE_CUSTOMER_GROUP_URL}/${id}`, CustomerGroupService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}