import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_SUBSCRIPTION_PLAN_URL,
    GET_SUBSCRIPTION_PLAN_BY_ID,
    GET_SUBSCRIPTION_PLAN_LIST,
    POST_SUBSCRIPTION_PLAN_URL,
    UPDATE_SUBSCRIPTION_PLAN_URL
} from "../helpers/Constant";

export default class SubscriptionPlanService {

    static getSubscriptionPlanList(params) {
        return axios.get(GET_SUBSCRIPTION_PLAN_LIST, {...SubscriptionPlanService.getAuthHeader(), params});
    }

    static addSubscriptionPlan(data) {
        return axios.post(POST_SUBSCRIPTION_PLAN_URL, data, SubscriptionPlanService.getAuthHeader());
    }

    static getSubscriptionPlanById(id) {
        return axios.get(`${GET_SUBSCRIPTION_PLAN_BY_ID}/${id}`, SubscriptionPlanService.getAuthHeader());
    }

    static editSubscriptionPlan(id, data) {
        return axios.put(`${UPDATE_SUBSCRIPTION_PLAN_URL}/${id}`, data, SubscriptionPlanService.getAuthHeader());
    }

    static deleteSubscriptionPlan(id) {
        return axios.delete(`${DELETE_SUBSCRIPTION_PLAN_URL}/${id}`, SubscriptionPlanService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}