import axios from "axios";
import {
    ACCESS_TOKEN,
    GET_COMPANY_BY_ID,
    GET_COMPANY_LIST,
    POST_COMPANY_URL,
    UPDATE_COMPANY_URL,
    VERIFY_COMPANY_URL
} from "../helpers/Constant";

export default class CompanyService {

    static getCompanyList(params) {
        return axios.get(GET_COMPANY_LIST, {...CompanyService.getAuthHeader(), params});
    }

    static addCompany(data) {
        return axios.post(POST_COMPANY_URL, data, CompanyService.getAuthHeader());
    }

    static getCompanyById(id) {
        return axios.get(`${GET_COMPANY_BY_ID}/${id}`, CompanyService.getAuthHeader());
    }

    static editCompany(id, data) {
        return axios.put(`${UPDATE_COMPANY_URL}/${id}`, data, CompanyService.getAuthHeader());
    }

    static verifyCompany(id, data) {
        return axios.put(`${VERIFY_COMPANY_URL}/${id}`, data, CompanyService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}