import React, {lazy, Suspense, useContext, useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import LoadingSuspense from './components/common/LoadingSuspense';
import {LOGIN_PATH, PAGE_403_PATH, PAGE_404_PATH, PAGE_500_PATH, ROOT_PATH, SELLER_PROFILE_EDIT_PATH, SELLER_REGISTRATION_PATH} from './routes/Slugs';
import PrivateRoute from './components/common/PrivateRoute';
import {AuthContext} from './contexts/AuthContextProvider';
import Interceptors from "./Interceptors";

const DefaultLayout = lazy(() => import('./components/layout/DefaultLayout'));
const RegSeller = lazy(() => import('./components/pages/seller/RegSeller'));
const WrappedLogin = lazy(() => import('./components/pages/login/Login'));
const Page403 = lazy(() => import('./components/pages/error_pages/Page403'));
const Page404 = lazy(() => import('./components/pages/error_pages/Page404'));
const Page500 = lazy(() => import('./components/pages/error_pages/Page500'));

const App = () => {

    const authContext = useContext(AuthContext);

    return (
        <div className="app_wrapper">
            <Suspense fallback={<LoadingSuspense/>}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={LOGIN_PATH} component={WrappedLogin}/>
                        <Route exact path={SELLER_REGISTRATION_PATH} component={RegSeller}/>
                        <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_404_PATH} component={Page404}/>
                        <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_403_PATH} component={Page403}/>
                        <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_500_PATH} component={Page500}/>
                        <PrivateRoute isLogin={authContext.isLogin} path={ROOT_PATH} component={DefaultLayout}/>
                    </Switch>
                    <Interceptors/>
                </BrowserRouter>
            </Suspense>
        </div>
    );

}

export default App;
