import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_STATIC_PAGE_URL,
    GET_STATIC_PAGE_BY_ID,
    GET_STATIC_PAGE_LIST,
    POST_STATIC_PAGE_URL,
    UPDATE_STATIC_PAGE_URL,
    GET_STATIC_PAGE_BY_TITLE
} from "../helpers/Constant";

export default class StaticPageService {

    static getStaticPagesList(params) {
        return axios.get(GET_STATIC_PAGE_LIST, {...StaticPageService.getAuthHeader(), params});
    }

    static addStaticPage(data) {
        return axios.post(POST_STATIC_PAGE_URL, data, StaticPageService.getAuthHeader());
    }

    static getStaticPageById(id) {
        return axios.get(`${GET_STATIC_PAGE_BY_ID}/${id}`, StaticPageService.getAuthHeader());
    }

    static getStaticPageByTitle(title) {
        return axios.get(`${GET_STATIC_PAGE_BY_TITLE}/${title}`, StaticPageService.getAuthHeader());
    }

    static editStaticPage(id, data) {
        return axios.put(`${UPDATE_STATIC_PAGE_URL}/${id}`, data, StaticPageService.getAuthHeader());
    }

    static deleteStaticPage(id, data) {
        return axios.delete(`${DELETE_STATIC_PAGE_URL}/${id}`, StaticPageService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": accessToken}};
    }

}