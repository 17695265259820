export const ROOT_PATH = '/';
export const DASHBOARD_PATH = `${ROOT_PATH}dashboard`;

export const LOGIN_PATH = `${ROOT_PATH}login`;

//product
export const PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}product-list`;
export const REQUESTED_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}requested-product-list`;
export const PRODUCT_VIEW_PATH = `${ROOT_PATH}product`;
export const ADD_PRODUCT_PATH = `${ROOT_PATH}add-product`;
export const EDIT_PRODUCT_PATH = `${ROOT_PATH}edit-product`;

//banner
export const BANNER_LIST_VIEW_PATH = `${ROOT_PATH}banner-list`;
export const ADD_BANNER_PATH = `${ROOT_PATH}add-banner`;
export const EDIT_BANNER_PATH = `${ROOT_PATH}edit-banner`;

//static_page
export const STATIC_PAGE_LIST_VIEW_PATH = `${ROOT_PATH}static-page-list`;
export const ADD_STATIC_PAGE_PATH = `${ROOT_PATH}add-static-page`;
export const EDIT_STATIC_PAGE_PATH = `${ROOT_PATH}edit-static-page`;


//category
export const CATEGORY_LIST_VIEW_PATH = `${ROOT_PATH}category-list`;
export const REQUESTED_CATEGORY_LIST_VIEW_PATH = `${ROOT_PATH}requested-category-list`;
export const CATEGORY_VIEW_PATH = `${ROOT_PATH}category`;
export const ADD_CATEGORY_PATH = `${ROOT_PATH}add-category`;
export const EDIT_CATEGORY_PATH = `${ROOT_PATH}edit-category`;

//User
export const USER_LIST_VIEW_PATH = `${ROOT_PATH}user-list`;
export const USER_VIEW_PATH = `${ROOT_PATH}user`;
export const ADD_USER_PATH = `${ROOT_PATH}add-user`;
export const EDIT_USER_PATH = `${ROOT_PATH}edit-user`;
export const SELLER_PROFILE_EDIT_PATH = `${ROOT_PATH}edit-seller-profile`;
export const USER_PROFILE_PATH = `${ROOT_PATH}user-profile`;
export const SELLER_LIST_VIEW_PATH = `${ROOT_PATH}seller-list`;
export const SELLER_VIEW_PATH = `${ROOT_PATH}seller-view`;
export const PENDING_SELLER_LIST_VIEW_PATH = `${ROOT_PATH}pending-seller-list`;
export const SELLER_REGISTRATION_PATH = `${ROOT_PATH}seller-registration`;

//Company
export const COMPANY_LIST_VIEW_PATH = `${ROOT_PATH}company-list`;
export const REQUESTED_COMPANIES_LIST_VIEW_PATH = `${ROOT_PATH}requested-companies-list`;
export const ADD_COMPANY_PATH = `${ROOT_PATH}add-company`;
export const EDIT_COMPANY_PATH = `${ROOT_PATH}edit-company`;

//Warehouse
export const WAREHOUSE_LIST_VIEW_PATH = `${ROOT_PATH}warehouse-list`;
export const ADD_WAREHOUSE_PATH = `${ROOT_PATH}add-warehouse`;
export const EDIT_WAREHOUSE_PATH = `${ROOT_PATH}edit-warehouse`;


//Order
export const ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-all`;
export const INIT_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-init`;
export const PENDING_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-pending`;
export const ON_THE_WAY_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-on-the-way`;
export const COMPLETED_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-completed`;
export const CANCELLED_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-cancelled`;
export const ORDER_VIEW_PATH = `${ROOT_PATH}order`;
export const ORDER_EDIT_PATH = `${ROOT_PATH}order-edit`;
export const ORDER_INVOICE_VIEW_PATH = `${ROOT_PATH}order-invoice`;

//role
export const ROLE_LIST_VIEW_PATH = `${ROOT_PATH}role-list`;
export const ADD_ROLE_PATH = `${ROOT_PATH}add-role`;
export const EDIT_ROLE_PATH = `${ROOT_PATH}edit-role`;

//stock
export const STOCK_LIST_VIEW_PATH = `${ROOT_PATH}stock-list`;
export const ADD_STOCK_PATH = `${ROOT_PATH}add-stock`;
export const EDIT_STOCK_PATH = `${ROOT_PATH}edit-stock`;

//stock out
export const STOCK_OUT_LIST_VIEW_PATH = `${ROOT_PATH}stock-out-list`;
export const ADD_STOCK_OUT_PATH = `${ROOT_PATH}add-stock-out`;
export const EDIT_STOCK_OUT_PATH = `${ROOT_PATH}edit-stock-out`;
export const VIEW_STOCK_OUT_PATH = `${ROOT_PATH}view-stock-out`;

//home page products
export const HOME_PAGE_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}homepage-product-list`;
export const ADD_HOME_PAGE_PRODUCT_PATH = `${ROOT_PATH}add-homepage-product`;
export const EDIT_HOME_PAGE_PRODUCT_PATH = `${ROOT_PATH}edit-homepage-product`;
export const APPLY_SUBSCRIPTION_PLAN = `${ROOT_PATH}apply-subscription-plan`;

//featured products
export const FEATURED_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}featured-product-list`;
export const ADD_FEATURED_PRODUCT_PATH = `${ROOT_PATH}add-featured-product`;
export const EDIT_FEATURED_PRODUCT_PATH = `${ROOT_PATH}edit-featured-product`;

//daily_deals products
export const DAILY_DEALS_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}daily-deals-product-list`;
export const ADD_DAILY_DEALS_PRODUCT_PATH = `${ROOT_PATH}add-daily-deals-product`;
export const EDIT_DAILY_DEALS_PRODUCT_PATH = `${ROOT_PATH}edit-daily-deals-product`;

//best_seller products
export const BEST_SELLER_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}best-seller-product-list`;
export const ADD_BEST_SELLER_PRODUCT_PATH = `${ROOT_PATH}add-best-seller-product`;
export const EDIT_BEST_SELLER_PRODUCT_PATH = `${ROOT_PATH}edit-best-seller-product`;

//global_config
export const GLOBAL_CONFIG_LIST_VIEW_PATH = `${ROOT_PATH}global-config-list`;
export const ADD_GLOBAL_CONFIG_PATH = `${ROOT_PATH}add-global-config`;
export const EDIT_GLOBAL_CONFIG_PATH = `${ROOT_PATH}edit-global-config`;

//promo-code
export const PROMO_CODE_LIST_VIEW_PATH = `${ROOT_PATH}promo-code-list`;
export const ADD_PROMO_CODE_PATH = `${ROOT_PATH}add-promo-code`;
export const EDIT_PROMO_CODE_PATH = `${ROOT_PATH}edit-promo-code`;

//area
export const AREA_LIST_VIEW_PATH = `${ROOT_PATH}area-list`;
export const ADD_AREA_PATH = `${ROOT_PATH}add-area`;
export const EDIT_AREA_PATH = `${ROOT_PATH}edit-area`;

//delivery partner
export const DELIVERY_PARTNER_LIST_VIEW_PATH = `${ROOT_PATH}delivery-partner-list`;
export const ADD_DELIVERY_PARTNER_PATH = `${ROOT_PATH}add-delivery-partner`;
export const EDIT_DELIVERY_PARTNER_PATH = `${ROOT_PATH}edit-delivery-partner`;
export const DELIVERY_PARTNER_VIEW_PATH = `${ROOT_PATH}delivery-partner`;


//product-bundle-offer
export const PRODUCT_BUNDLE_OFFER_LIST_VIEW_PATH = `${ROOT_PATH}product-bundle-offer-list`;
export const ADD_PRODUCT_BUNDLE_OFFER_PATH = `${ROOT_PATH}add-product-bundle-offer`;
export const EDIT_PRODUCT_BUNDLE_OFFER_PATH = `${ROOT_PATH}edit-product-bundle-offer`;

//campaign
export const CAMPAIGN_LIST_VIEW_PATH = `${ROOT_PATH}campaign-list`;
export const ADD_CAMPAIGN_PATH = `${ROOT_PATH}add-campaign`;
export const EDIT_CAMPAIGN_PATH = `${ROOT_PATH}edit-campaign`;

//seller-ledger
export const SELLER_LEDGER_LIST_VIEW_PATH = `${ROOT_PATH}seller-ledger-list`;
export const ADD_SELLER_LEDGER_PATH = `${ROOT_PATH}add-seller-payment`;
export const EDIT_SELLER_LEDGER_PATH = `${ROOT_PATH}edit-seller-ledger`;

//seller-ledger
export const DELIVERY_PARTNER_LEDGER_LIST_VIEW_PATH = `${ROOT_PATH}delivery-partner-ledger-list`;
export const ADD_DELIVERY_PARTNER_LEDGER_PATH = `${ROOT_PATH}add-delivery-partner-payment`;
export const EDIT_DELIVERY_PARTNER_LEDGER_PATH = `${ROOT_PATH}edit-delivery-partner-ledger`;

//subscriptionPlan
export const SUBSCRIPTION_PLAN_LIST_VIEW_PATH = `${ROOT_PATH}subscription-plan-list`;
export const ADD_SUBSCRIPTION_PLAN_PATH = `${ROOT_PATH}add-subscription-plan`;
export const EDIT_SUBSCRIPTION_PLAN_PATH = `${ROOT_PATH}edit-subscription-plan`;

// customer group
export const CUSTOMER_GROUP_LIST_VIEW_PATH = `${ROOT_PATH}customer-group-list`;
export const ADD_CUSTOMER_GROUP_PATH = `${ROOT_PATH}add-customer-group`;
export const EDIT_CUSTOMER_GROUP_PATH = `${ROOT_PATH}edit-customer-group`;

// Info
export const VENDOR_INFO_PATH = `${ROOT_PATH}vendor-info`;

//chatting
export const CHATTING_PATH = `${ROOT_PATH}chatting`;


export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;