import React, {createContext, useState} from 'react';
import SellerLedgerService from "../services/SellerLedgerService";
import {Toast} from "../components/common/Toast";

export const SellerLedgerContext = createContext("SellerLedgerContext");

const SellerLedgerContextProvider = ({children}) => {

    const [sellerLedgerList, setSellerLedgerList] = useState([]);
    const [loadingSellerLedgerList, setLoadingSellerLedgerList] = useState(false);
    const [sellerLedgerListErrorMsg, setSellerLedgerListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getSellerLedgerList = async (id,params) => {

        try {
            setLoadingSellerLedgerList(true);
            setSellerLedgerListErrorMsg('');
            const response = await SellerLedgerService.getSellerLedgerList(id, params);
            setSellerLedgerList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingSellerLedgerList(false);
        } catch (error) {
            setLoadingSellerLedgerList(false);
            const message = error.response ? error.response.data.message : error.message;
            setSellerLedgerListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <SellerLedgerContext.Provider
            value={{
                getSellerLedgerList,
                sellerLedgerList,
                totalElements,
                loadingSellerLedgerList,
                sellerLedgerListErrorMsg
            }}
        >
            {children}
        </SellerLedgerContext.Provider>
    );
}

export default SellerLedgerContextProvider;