import React, {createContext, useState} from 'react';
import PromoCodeService from "../services/PromoCodeService";
import {Toast} from "../components/common/Toast";

export const PromoCodeContext = createContext("PromoCodeContext");

const PromoCodeContextProvider = ({children}) => {

    const [promoCodeList, setPromoCodeList] = useState([]);
    const [loadingPromoCodeList, setLoadingPromoCodeList] = useState(false);
    const [promoCodeListErrorMsg, setPromoCodeListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getPromoCodeList = async (params) => {

        try {
            setLoadingPromoCodeList(true);
            setPromoCodeListErrorMsg('');
            const response = await PromoCodeService.getPromoCodeList(params);
            setPromoCodeList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingPromoCodeList(false);
        } catch (error) {
            setLoadingPromoCodeList(false);
            const message = error.response ? error.response.data.message : error.message;
            setPromoCodeListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <PromoCodeContext.Provider
            value={{
                getPromoCodeList,
                promoCodeList,
                totalElements,
                loadingPromoCodeList,
                promoCodeListErrorMsg
            }}
        >
            {children}
        </PromoCodeContext.Provider>
    );
}

export default PromoCodeContextProvider;