import React, {createContext, useState} from 'react';
import StockOutService from "../services/StockOutService";
import {Toast} from "../components/common/Toast";

export const StockOutContext = createContext("StockOutContext");

const StockOutContextProvider = ({children}) => {

    const [stockOutList, setStockOutList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingStockOutList, setLoadingStockOutList] = useState(false);
    const [stockOutListErrorMsg, setStockOutListErrorMsg] = useState('');

    const getStockOutList = async (page = 0, size = 10) => {

        try {
            setLoadingStockOutList(true);
            setStockOutListErrorMsg('');
            const response = await StockOutService.getStockOutsList(page, size);
            setStockOutList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingStockOutList(false);
        } catch (error) {
            setLoadingStockOutList(false);
            const message = error.response ? error.response.data.message ? error.response.data.message : error.response.data : error.message;
            setStockOutListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <StockOutContext.Provider
            value={{
                getStockOutList,
                stockOutList,
                totalElements,
                loadingStockOutList,
                stockOutListErrorMsg
            }}
        >
            {children}
        </StockOutContext.Provider>
    );
}

export default StockOutContextProvider;