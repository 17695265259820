import React, {createContext, useState} from 'react';
import ProductBundleOfferService from "../services/ProductBundleOfferService";
import {Toast} from "../components/common/Toast";

export const ProductBundleOfferContext = createContext("ProductBundleOfferContext");

const ProductBundleOfferContextProvider = ({children}) => {

    const [productBundleOfferList, setProductBundleOfferList] = useState([]);
    const [loadingProductBundleOfferList, setLoadingProductBundleOfferList] = useState(false);
    const [productBundleOfferListErrorMsg, setProductBundleOfferListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getProductBundleOfferList = async (params) => {

        try {
            setLoadingProductBundleOfferList(true);
            setProductBundleOfferListErrorMsg('');
            const response = await ProductBundleOfferService.getProductBundleOfferList(params);
            setProductBundleOfferList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingProductBundleOfferList(false);
        } catch (error) {
            setLoadingProductBundleOfferList(false);
            const message = error.response ? error.response.data.message : error.message;
            setProductBundleOfferListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <ProductBundleOfferContext.Provider
            value={{
                getProductBundleOfferList,
                productBundleOfferList,
                totalElements,
                loadingProductBundleOfferList,
                productBundleOfferListErrorMsg
            }}
        >
            {children}
        </ProductBundleOfferContext.Provider>
    );
}

export default ProductBundleOfferContextProvider;