import axios from "axios";
import {
    ACCESS_TOKEN,
    CHANGE_PASSWORD_URL,
    CREATE_USER_URL,
    GET_USER_BY_ID,
    GET_USER_LIST,
    GET_USER_PROFILE,
    UPDATE_SELLER_PROFILE_URL,
    UPDATE_USER_URL
} from "../helpers/Constant";

export default class UserService {

    static getUsersList(params) {
        return axios.get(GET_USER_LIST, {...UserService.getAuthHeader(), params});
    }

    static addUser(data) {
        return axios.post(CREATE_USER_URL, data, UserService.getAuthHeader());
    }

    static updateUser(data, id) {
        return axios.put(`${UPDATE_USER_URL}/${id}`, data, UserService.getAuthHeader());
    }

    static changeUserPassword(data, id) {
        return axios.put(`${CHANGE_PASSWORD_URL}/${id}`, data, UserService.getAuthHeader());
    }

    static getUserById(id) {
        return axios.get(`${GET_USER_BY_ID}/${id}`, UserService.getAuthHeader());
    }

    static getUserProfile() {
        return axios.get(GET_USER_PROFILE, UserService.getAuthHeader());
    }

    static updateSellerProfile(data) {
        return axios.put(UPDATE_SELLER_PROFILE_URL, data, UserService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": `${accessToken}`}};
    }

}