export default {
    ALL: 'ALL',

    CREATE_PRODUCTS: 'CREATE_PRODUCTS',
    READ_PRODUCTS: 'READ_PRODUCTS',
    UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
    DELETE_PRODUCTS: 'DELETE_PRODUCTS',
    VERIFY_PRODUCTS: 'VERIFY_PRODUCTS',
    CREATE_HOMEPAGE_PRODUCTS: 'CREATE_HOMEPAGE_PRODUCTS',
    READ_HOMEPAGE_PRODUCTS: 'READ_HOMEPAGE_PRODUCTS',
    UPDATE_HOMEPAGE_PRODUCTS: 'UPDATE_HOMEPAGE_PRODUCTS',
    DELETE_HOMEPAGE_PRODUCTS: 'DELETE_HOMEPAGE_PRODUCTS',
    CREATE_FEATURED_PRODUCTS: 'CREATE_FEATURED_PRODUCTS',
    READ_FEATURED_PRODUCTS: 'READ_FEATURED_PRODUCTS',
    UPDATE_FEATURED_PRODUCTS: 'UPDATE_FEATURED_PRODUCTS',
    DELETE_FEATURED_PRODUCTS: 'DELETE_FEATURED_PRODUCTS',
    CREATE_DAILY_DEALS_PRODUCTS: 'CREATE_DAILY_DEALS_PRODUCTS',
    READ_DAILY_DEALS_PRODUCTS: 'READ_DAILY_DEALS_PRODUCTS',
    UPDATE_DAILY_DEALS_PRODUCTS: 'UPDATE_DAILY_DEALS_PRODUCTS',
    DELETE_DAILY_DEALS_PRODUCTS: 'DELETE_DAILY_DEALS_PRODUCTS',
    CREATE_BEST_SELLER_PRODUCTS: 'CREATE_BEST_SELLER_PRODUCTS',
    READ_BEST_SELLER_PRODUCTS: 'READ_BEST_SELLER_PRODUCTS',
    UPDATE_BEST_SELLER_PRODUCTS: 'UPDATE_BEST_SELLER_PRODUCTS',
    DELETE_BEST_SELLER_PRODUCTS: 'DELETE_BEST_SELLER_PRODUCTS',
    READ_SINGLE_PRODUCTS: 'READ_SINGLE_PRODUCTS',
    READ_SINGLE_HOMEPAGE_PRODUCTS: 'READ_SINGLE_HOMEPAGE_PRODUCTS',
    READ_SINGLE_FEATURED_PRODUCTS: 'READ_SINGLE_FEATURED_PRODUCTS',
    READ_SINGLE_DAILY_DEALS_PRODUCTS: 'READ_SINGLE_DAILY_DEALS_PRODUCTS',
    READ_SINGLE_BEST_SELLER_PRODUCTS: 'READ_SINGLE_BEST_SELLER_PRODUCTS',

    CREATE_BANNERS: 'CREATE_BANNERS',
    READ_SINGLE_BANNER: 'READ_SINGLE_BANNER',
    READ_BANNERS: 'READ_BANNERS',
    UPDATE_BANNERS: 'UPDATE_BANNERS',
    DELETE_BANNERS: 'DELETE_BANNERS',

    CREATE_CARTS: 'CREATE_CARTS',
    READ_SINGLE_CART: 'READ_SINGLE_CART',
    READ_CARTS: 'READ_CARTS',
    UPDATE_CARTS: 'UPDATE_CARTS',

    CREATE_CATEGORIES: 'CREATE_CATEGORIES',
    READ_SINGLE_CATEGORY: 'READ_SINGLE_CATEGORY',
    READ_CATEGORIES: 'READ_CATEGORIES',
    UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
    DELETE_CATEGORIES: 'DELETE_CATEGORIES',
    CREATE_HOT_CATEGORIES: 'CREATE_HOT_CATEGORIES',
    VERIFY_CATEGORIES: 'VERIFY_CATEGORIES',

    CREATE_COMPANIES: 'CREATE_COMPANIES',
    READ_COMPANIES: 'READ_COMPANIES',
    READ_SINGLE_COMPANY: 'READ_SINGLE_COMPANY',
    UPDATE_COMPANIES: 'UPDATE_COMPANIES',
    DELETE_COMPANIES: 'DELETE_COMPANIES',

    CREATE_ORDERS: 'CREATE_ORDERS',
    READ_SINGLE_ORDER: 'READ_SINGLE_ORDER',
    READ_ORDERS: 'READ_ORDERS',
    UPDATE_ORDERS: 'UPDATE_ORDERS',
    DELETE_ORDERS: 'DELETE_ORDERS',
    PROCESS_ORDERS: 'PROCESS_ORDERS',
    CUSTOMER_ORDERS: 'CUSTOMER_ORDERS',

    CREATE_PRODUCT_VARIANTS: 'CREATE_PRODUCT_VARIANTS',
    READ_SINGLE_PRODUCT_VARIANT: 'READ_SINGLE_PRODUCT_VARIANT',
    UPDATE_PRODUCT_VARIANTS: 'UPDATE_PRODUCT_VARIANTS',
    DELETE_PRODUCT_VARIANTS: 'DELETE_PRODUCT_VARIANTS',

    CREATE_ROLES: 'CREATE_ROLES',
    READ_ROLES: 'READ_ROLES',
    READ_SINGLE_ROLE: 'READ_SINGLE_ROLE',
    UPDATE_ROLES: 'UPDATE_ROLES',
    DELETE_ROLES: 'DELETE_ROLES',

    CREATE_STOCKS: 'CREATE_STOCKS',
    READ_STOCKS: 'READ_STOCKS',
    UPDATE_STOCKS: 'UPDATE_STOCKS',
    DELETE_STOCKS: 'DELETE_STOCKS',

    CREATE_STOCK_OUTS: 'CREATE_STOCK_OUTS',
    READ_STOCK_OUTS: 'READ_STOCK_OUTS',
    READ_SINGLE_STOCK_OUT: 'READ_SINGLE_STOCK_OUT',
    UPDATE_STOCK_OUTS: 'UPDATE_STOCK_OUTS',
    DELETE_STOCK_OUTS: 'DELETE_STOCK_OUTS',
    VERIFY_STOCKS: 'VERIFY_STOCKS',

    UPDATE_SELLER: 'UPDATE_SELLER',
    UPDATE_SELLER_PROFILE: 'UPDATE_SELLER_PROFILE',
    CREATE_SELLER_PHONE: 'CREATE_SELLER_PHONE',
    UPDATE_SELLER_PHONE: 'UPDATE_SELLER_PHONE',
    DELETE_SELLER_PHONE: 'DELETE_SELLER_PHONE',
    CREATE_SELLER_DELIVERY_ADDRESS: 'CREATE_SELLER_DELIVERY_ADDRESS',
    UPDATE_SELLER_DELIVERY_ADDRESS: 'UPDATE_SELLER_DELIVERY_ADDRESS',
    DELETE_SELLER_DELIVERY_ADDRESS: 'DELETE_SELLER_DELIVERY_ADDRESS',

    CREATE_STATIC_PAGES: 'CREATE_STATIC_PAGES',
    READ_SINGLE_STATIC_PAGE: 'READ_SINGLE_STATIC_PAGE',
    READ_SINGLE_STATIC_PAGE_BY_TITLE: 'READ_SINGLE_STATIC_PAGE_BY_TITLE',
    UPDATE_STATIC_PAGES: 'UPDATE_STATIC_PAGES',
    DELETE_STATIC_PAGES: 'DELETE_STATIC_PAGES',

    READ_PROFILES: 'READ_PROFILES',
    READ_SINGLE_PROFILE: 'READ_SINGLE_PROFILE',
    READ_LITE_PROFILE: 'READ_LITE_PROFILE',
    SIGNUP: 'SIGNUP',
    UPDATE_USERS_PASSWORD: 'UPDATE_USERS_PASSWORD',
    UPDATE_CUSTOMERS_PASSWORD: 'UPDATE_CUSTOMERS_PASSWORD',
    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    UPDATE_CUSTOMER_PROFILE: 'UPDATE_CUSTOMER_PROFILE',
    CREATE_CUSTOMER_PHONE: 'CREATE_CUSTOMER_PHONE',
    UPDATE_CUSTOMER_PHONE: 'UPDATE_CUSTOMER_PHONE',
    DELETE_CUSTOMER_PHONE: 'DELETE_CUSTOMER_PHONE',
    CREATE_CUSTOMER_DELIVERY_ADDRESS: 'CREATE_CUSTOMER_DELIVERY_ADDRESS',
    UPDATE_CUSTOMER_DELIVERY_ADDRESS: 'UPDATE_CUSTOMER_DELIVERY_ADDRESS',
    DELETE_CUSTOMER_DELIVERY_ADDRESS: 'DELETE_CUSTOMER_DELIVERY_ADDRESS',
    CREATE_USER: 'CREATE_USER',
    READ_SINGLE_USER: 'READ_SINGLE_USER',
    READ_USERS: 'READ_USERS',
    UPDATE_USERS: 'UPDATE_USERS',
    DELETE_USERS: 'DELETE_USERS',
    ALREADY_EXISTS_PHONE: 'ALREADY_EXISTS_PHONE',
    CREATE_SSL: 'CREATE_SSL',
    SUCCESS_SSL: 'SUCCESS_SSL',

    CREATE_SELLER: 'CREATE_SELLER',
    READ_SELLERS: 'READ_SELLERS',
    UPDATE_SELLERS: 'UPDATE_SELLERS',
    DELETE_SELLERS: 'DELETE_SELLERS',
    VERIFY_SELLERS: 'VERIFY_SELLERS',

    CREATE_WAREHOUSES: 'CREATE_WAREHOUSES',
    READ_SINGLE_WAREHOUSE: 'READ_SINGLE_WAREHOUSE',
    READ_WAREHOUSES: 'READ_WAREHOUSES',
    UPDATE_WAREHOUSES: 'UPDATE_WAREHOUSES',
    DELETE_WAREHOUSES: 'DELETE_WAREHOUSES',

    CREATE_GLOBAL_CONFIGS: 'CREATE_GLOBAL_CONFIGS',
    READ_GLOBAL_CONFIGS: 'READ_GLOBAL_CONFIGS',
    READ_SINGLE_GLOBAL_CONFIG: 'READ_SINGLE_GLOBAL_CONFIG',
    UPDATE_GLOBAL_CONFIGS: 'UPDATE_GLOBAL_CONFIGS',
    DELETE_GLOBAL_CONFIGS: 'DELETE_GLOBAL_CONFIGS',

    CREATE_PROMO_CODES: 'CREATE_PROMO_CODES',
    READ_SINGLE_PROMO_CODE: 'READ_SINGLE_PROMO_CODE',
    READ_PROMO_CODES: 'READ_PROMO_CODES',
    UPDATE_PROMO_CODES: 'UPDATE_PROMO_CODES',
    DELETE_PROMO_CODES: 'DELETE_PROMO_CODES',

    CREATE_AREAS: 'CREATE_AREAS',
    READ_SINGLE_AREA: 'READ_SINGLE_AREA',
    READ_AREAS: 'READ_AREAS',
    UPDATE_AREAS: 'UPDATE_AREAS',
    DELETE_AREAS: 'DELETE_AREAS',

    CREATE_SUBSCRIPTION_PLANS: 'CREATE_SUBSCRIPTION_PLANS',
    READ_SINGLE_SUBSCRIPTION_PLAN: 'READ_SINGLE_SUBSCRIPTION_PLAN',
    READ_SUBSCRIPTION_PLANS: 'READ_SUBSCRIPTION_PLANS',
    UPDATE_SUBSCRIPTION_PLANS: 'UPDATE_SUBSCRIPTION_PLANS',
    DELETE_SUBSCRIPTION_PLANS: 'DELETE_SUBSCRIPTION_PLANS',

    ASSIGN_SUBSCRIPTION_PLAN: 'ASSIGN_SUBSCRIPTION_PLAN',

    READ_SELLER_LEDGERS: 'READ_SELLER_LEDGERS',
    CANCEL_SELLER_PAYMENT: 'CANCEL_SELLER_PAYMENT',
    CREATE_SELLER_PAYMENT: 'CREATE_SELLER_PAYMENT',
    PRODUCT_ACTIVATE_DEACTIVATE: 'PRODUCT_ACTIVATE_DEACTIVATE',

    READ_REQUESTED_COMPANIES: 'READ_REQUESTED_COMPANIES',
    VERIFY_COMPANIES: 'VERIFY_COMPANIES',

    CREATE_PRODUCT_BUNDLE_OFFERS: 'CREATE_PRODUCT_BUNDLE_OFFERS',
    READ_SINGLE_PRODUCT_BUNDLE_OFFER: 'READ_SINGLE_PRODUCT_BUNDLE_OFFER',
    READ_PRODUCT_BUNDLE_OFFERS: 'READ_PRODUCT_BUNDLE_OFFERS',
    UPDATE_PRODUCT_BUNDLE_OFFERS: 'UPDATE_PRODUCT_BUNDLE_OFFERS',
    DELETE_PRODUCT_BUNDLE_OFFERS: 'DELETE_PRODUCT_BUNDLE_OFFERS',
    READ_OWN_PAYMENT_HISTORY: 'READ_OWN_PAYMENT_HISTORY',

    CREATE_DELIVERY_PARTNERS: 'CREATE_DELIVERY_PARTNERS',
    READ_SINGLE_DELIVERY_PARTNER: 'READ_SINGLE_DELIVERY_PARTNER',
    READ_DELIVERY_PARTNERS: 'READ_DELIVERY_PARTNERS',
    UPDATE_DELIVERY_PARTNERS: 'UPDATE_DELIVERY_PARTNERS',
    DELETE_DELIVERY_PARTNERS: 'DELETE_DELIVERY_PARTNERS',

    CREATE_DELIVERY_PARTNER_PAYMENT: 'CREATE_DELIVERY_PARTNER_PAYMENT',
    READ_DELIVERY_PARTNER_PAYMENT_HISTORY: 'READ_DELIVERY_PARTNER_PAYMENT_HISTORY',

    READ_DELIVERY_PARTNER_ACCOUNTS: 'READ_DELIVERY_PARTNER_ACCOUNTS',
    CANCEL_DELIVERY_PARTNER_PAYMENT: 'CANCEL_SELLER_PAYMENT',

    CREATE_CUSTOMER_GROUPS: 'CREATE_CUSTOMER_GROUPS',
    READ_SINGLE_CUSTOMER_GROUP: 'READ_SINGLE_CUSTOMER_GROUP',
    READ_CUSTOMER_GROUPS: 'READ_CUSTOMER_GROUPS',
    UPDATE_CUSTOMER_GROUPS: 'UPDATE_CUSTOMER_GROUPS',
    DELETE_CUSTOMER_GROUPS: 'DELETE_CUSTOMER_GROUPS',
    READ_SELLER_ACCOUNTS: 'READ_SELLER_ACCOUNTS',

    CREATE_CAMPAIGNS: 'CREATE_CAMPAIGNS',
    READ_SINGLE_CAMPAIGN: 'READ_SINGLE_CAMPAIGN',
    READ_CAMPAIGNS: 'READ_CAMPAIGNS',
    UPDATE_CAMPAIGNS: 'UPDATE_CAMPAIGNS',
    DELETE_CAMPAIGNS: 'DELETE_CAMPAIGNS',
    
    READ_PENDING_FOR_SUPPLIER_ORDERS: 'READ_PENDING_FOR_SUPPLIER_ORDERS',
    READ_PENDING_FOR_DELIVERY_MAN_ORDERS: 'READ_PENDING_FOR_DELIVERY_MAN_ORDERS',
    READ_CANCELED_ORDERS: 'READ_CANCELED_ORDERS',
    READ_COMPLETED_ORDERS: 'READ_COMPLETED_ORDERS',
    READ_PENDING_FOR_HANDOVER_ORDERS: "READ_PENDING_FOR_HANDOVER_ORDERS"

}