import axios from "axios";
import {
    ACCESS_TOKEN,
    CREATE_PRODUCT_URL,
    CREATE_VARIANT_URL,
    DELETE_PRODUCT_URL,
    DELETE_VARIANT_URL,
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_LIST,
    UPDATE_PRODUCT_URL,
    UPDATE_VARIANT_URL,
    VERIFY_PRODUCT_URL,
    APPLY_SUBSCRIPTION_PLAN,
    PRODUCT_ACTIVATE_DEACTIVATE_URL
} from "../helpers/Constant";

export default class ProductService {

    static getProductsList(params) {
        return axios.get(GET_PRODUCT_LIST, {...ProductService.getAuthHeader(), params});
    }

    static addProduct(data) {
        return axios.post(CREATE_PRODUCT_URL, data, ProductService.getAuthHeader());
    }

    static deleteProduct(id) {
        return axios.delete(`${DELETE_PRODUCT_URL}/${id}`, ProductService.getAuthHeader());
    }

    static updateProduct(data, id) {
        return axios.put(`${UPDATE_PRODUCT_URL}/${id}`, data, ProductService.getAuthHeader());
    }

    static getProductById(id) {
        return axios.get(`${GET_PRODUCT_BY_ID}/${id}`, ProductService.getAuthHeader());
    }

    static addVariant(productId, data) {
        return axios.post(`${CREATE_VARIANT_URL}/${productId}`, data, ProductService.getAuthHeader());
    }

    static updateVariant(data, id) {
        return axios.put(`${UPDATE_VARIANT_URL}/${id}`, data, ProductService.getAuthHeader());
    }

    static deleteVariant(productId, id) {
        return axios.delete(`${DELETE_VARIANT_URL}/${productId}/${id}`, ProductService.getAuthHeader());
    }

    static verifyProduct(id, body) {
        return axios.put(`${VERIFY_PRODUCT_URL}/${id}`, body, ProductService.getAuthHeader());
    }

    static applyPlan(body) {
        return axios.put(APPLY_SUBSCRIPTION_PLAN, body, ProductService.getAuthHeader());
    }

    static activateDeactivate(id, body) {
        return axios.put(`${PRODUCT_ACTIVATE_DEACTIVATE_URL}/${id}`, body, ProductService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": `${accessToken}`}};
    }

}