import React, {createContext, useState} from 'react';
import ProductService from "../services/ProductService";
import {Toast} from "../components/common/Toast";

export const ProductContext = createContext("ProductContext");

const AuthContextProvider = ({children}) => {

    const [productList, setProductList] = useState([]);
    const [totalElements, setTotalElements] = useState([0]);
    const [loadingProductList, setLoadingProductList] = useState(false);
    const [productListErrorMsg, setProductListErrorMsg] = useState('');

    const getProductList = async (params) => {

        try {
            setLoadingProductList(true);
            setProductListErrorMsg('');
            const response = await ProductService.getProductsList(params);
            setProductList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingProductList(false);
        } catch (error) {
            setLoadingProductList(false);
            const message = error.response ? error.response.data : error.message;
            setProductListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <ProductContext.Provider
            value={{
                getProductList,
                productList,
                loadingProductList,
                productListErrorMsg,
                totalElements
            }}
        >
            {children}
        </ProductContext.Provider>
    );
}

export default AuthContextProvider;